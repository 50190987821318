import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    isExisteUsuario(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/usuario-info/existe-usuario', { params: data })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getPlanos() {
      return new Promise((resolve, reject) => {
        axios.get('/fut/assinatura-agente/plano')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    registrar(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.post('/fut/auth/agente/registrar', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
