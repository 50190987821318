export default {

  percentDiff(num1, num2) {
    return ((num2 - num1) / num1) * 100
  },

  dateDiff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24))
  },

  valorPercentual(valorTotal, percentual) {
    return (valorTotal * percentual) / 100
  },
}
