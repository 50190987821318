var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-overlay',{attrs:{"show":_vm.loadingOverlay,"rounded":"sm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"lg":"12"}},[_c('h5',[_vm._v("Cadastrar novos usuários")])])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","state":errors.length > 0 ? false:null},on:{"change":_vm.isExisteUsuario},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.novoUsuario)?_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome")]),_c('validation-provider',{attrs:{"name":"Nome","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","state":errors.length > 0 ? false:null},model:{value:(_vm.data.nome),callback:function ($$v) {_vm.$set(_vm.data, "nome", $$v)},expression:"data.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3557424739)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sobrenome"}},[_vm._v("Sobrenome")]),_c('validation-provider',{attrs:{"name":"Sobrenome","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sobrenome","state":errors.length > 0 ? false:null},model:{value:(_vm.data.sobrenome),callback:function ($$v) {_vm.$set(_vm.data, "sobrenome", $$v)},expression:"data.sobrenome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3258644771)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"plano"}},[_vm._v("Plano")]),_c('validation-provider',{attrs:{"name":"Plano","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.planos,"input-id":"plano","value-field":"id","text-field":"nome","state":errors.length > 0 ? false:null},model:{value:(_vm.planoSelected),callback:function ($$v) {_vm.planoSelected=$$v},expression:"planoSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.variacaoSelected)?_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"variacao"}},[_vm._v("Variação")]),_c('validation-provider',{attrs:{"name":"variacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.variacaoSelected,"input-id":"variacao","value-field":"id","text-field":"descricao","state":errors.length > 0 ? false:null},model:{value:(_vm.data.planoVariacao),callback:function ($$v) {_vm.$set(_vm.data, "planoVariacao", $$v)},expression:"data.planoVariacao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2806685833)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Cadastrar ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }