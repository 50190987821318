<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-overlay
            :show="loadingOverlay"
            rounded="sm"
          >
            <validation-observer ref="simpleRules">
              <b-form @submit.prevent="submitForm">
                <b-row>
                  <b-col
                    lg="12"
                    class="mb-2"
                  >
                    <h5>Cadastrar novos usuários</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="email">Email</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="nome"
                          v-model="data.email"
                          :state="errors.length > 0 ? false:null"
                          @change="isExisteUsuario"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="novoUsuario">
                  <b-col lg="6">
                    <b-form-group>
                      <label for="nome">Nome</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Nome"
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="nome"
                          v-model="data.nome"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="sobrenome">Sobrenome</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Sobrenome"
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="sobrenome"
                          v-model="data.sobrenome"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="plano">Plano</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Plano"
                        rules="required"
                      >
                        <b-form-select
                          v-model="planoSelected"
                          :options="planos"
                          input-id="plano"
                          value-field="id"
                          text-field="nome"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="variacaoSelected"
                    lg="6"
                  >
                    <b-form-group>
                      <label for="variacao">Variação</label>
                      <validation-provider
                        #default="{ errors }"
                        name="variacao"
                        rules="required"
                      >
                        <b-form-select
                          v-model="data.planoVariacao"
                          :options="variacaoSelected"
                          input-id="variacao"
                          value-field="id"
                          text-field="descricao"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-button
                      variant="primary"
                      type="submit"
                    >
                      Cadastrar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import {
  BRow, BButton, BOverlay, BCol, BFormGroup, BCard, BFormSelect, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import cadastroUsuarioStoreModule from './cadastroUsuarioStoreModule'

const CADASTRO_USUARIO_FUT_STORE_MODULE_NAME = 'cadastro-usuario-fut'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BOverlay,
    BCard,
    BFormSelect,
    BForm,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlay: false,
      planos: [],
      planoSelected: [],
      data: {
        nome: null,
        sobrenome: null,
        email: null,
        planoVariacao: null,
      },
      required,
      novoUsuario: false,
    }
  },

  computed: {
    variacaoSelected() {
      const plano = this.planos.find(v => v.id === this.planoSelected)
      if (plano != null) {
        return plano.variacao
      }
      return null
    },
  },

  created() {
    if (!store.hasModule(CADASTRO_USUARIO_FUT_STORE_MODULE_NAME)) store.registerModule(CADASTRO_USUARIO_FUT_STORE_MODULE_NAME, cadastroUsuarioStoreModule)
    this.getPlanos()
  },

  beforeDestroy() {
    if (store.hasModule(CADASTRO_USUARIO_FUT_STORE_MODULE_NAME)) store.unregisterModule(CADASTRO_USUARIO_FUT_STORE_MODULE_NAME)
  },

  methods: {

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('cadastro-usuario-fut/registrar', this.data)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Usuário cadastrado no produto',
                html: `Para os novos usuários, Será enviado um e-mail para ${this.data.email} com os dados de acesso.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              })
              this.data.nome = null
              this.data.sobrenome = null
              this.data.email = null
              this.data.planoVariacao = null
              this.$refs.simpleRules.reset()
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.data,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },

    getPlanos() {
      this.$store.dispatch('cadastro-usuario-fut/getPlanos')
        .then(response => { this.planos = response.data })
    },

    isExisteUsuario() {
      this.$store.dispatch('cadastro-usuario-fut/isExisteUsuario', { email: this.data.email })
        .then(response => { this.novoUsuario = !response.data })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
